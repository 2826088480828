import { FC } from 'react'
import gql from 'graphql-tag'
import styled, { css } from 'styled-components'
import { HeaderMobile_UserFragment } from 'generated/graphql'
import { TMobileHeader } from 'app.layout/constant/pageLayout.type'
import HeaderLogo from 'app.layout/desktop/Hearder/component/HeaderLogo/HeaderLogo'
import HeaderCalendarIcon from 'components/Header/HeaderCalendarIcon'
import HeaderNotificationIcon from 'components/Header/HeaderNotificationIcon'
import HeaderScrapIcon from 'components/Header/HeaderScrapIcon'
import HeaderChatIconMobile from './component/HeaderChatIcon.mobile'
import SearchBarMobile from './component/SearchBar/SearchBar.mobile'

gql`
  fragment HeaderMobile_user on User {
    id
    scrapCount
    notificationUnreadCount(notificationType: $notificationType)
  }
`

interface IFHeaderMobileProps extends TMobileHeader {
  currentUser?: HeaderMobile_UserFragment | null
  isMainLogoTitle?: boolean
}

const HeaderMobile: FC<IFHeaderMobileProps> = ({
  currentUser,
  isSticky = false,
  searchBar = false,
  isMainLogoTitle = false,
}) => {
  return (
    <StyledWrapper isSticky={isSticky}>
      <div className="header-container">
        {isMainLogoTitle ? (
          <h1>
            <HeaderLogo className="logo" noColor />
          </h1>
        ) : (
          <HeaderLogo className="logo" noColor />
        )}
        <div className="flex-box">
          <HeaderCalendarIcon />
          <HeaderNotificationIcon
            notificationsUnreadCount={currentUser?.notificationUnreadCount || 0}
          />
          <HeaderScrapIcon scrapCount={currentUser?.scrapCount} />
          <HeaderChatIconMobile />
        </div>
      </div>
      {searchBar && <SearchBarMobile />}
    </StyledWrapper>
  )
}

export default HeaderMobile

type TStyleProps = {
  isSticky: boolean
}

const StyledWrapper = styled.div<TStyleProps>`
  ${({ theme, isSticky }) => css`
    position: ${isSticky ? 'sticky' : 'unset'};
    top: 0;
    z-index: 300;
    padding: 0 6px;
    background-color: ${theme.color.primary_2};
    .header-container {
      display: flex;
      align-items: center;
      padding: 0px 14px;
      justify-content: space-between;
      height: 50px;
      .flex-box {
        display: flex;
        align-items: center;
      }

      .logo {
        width: 94px;
        color: #fff;
      }
    }
  `}
`
